.eventResultMarker {
    height: 30px;
    width: 30px;
}

.badgeIndicator {
    font-size: 30px;
}

.eventMarkerMap {
    background-color: #1069ae;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    border-radius: 100%;
}

.eventMarkerMap_small {
    background-color: #1069ae;
    color: white;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: center;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    border-radius: 100%;
}

.eventMarkerList {
    background-color: #1069ae;
    color: white;
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: center;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    border-radius: 100%;
    margin-right: 5px;
}

.eventName {
    font-weight: bold;
}

.eventDescription {
    margin-top: 5px;
}


.card-subtitle {
    font-weight: bold;
    padding-bottom: 5px;
}

.eventDescriptionContainer {
    padding-left: 5px;
}

/*.eventDescriptionContainer h1,h2,h3,h4,h5,h6  {
    font-weight: bold;
    font-size: 14px;
}*/


.eventListContainer {
    position: absolute;
    overflow-y: scroll;
    width: 100%;
    height: 60vh;
}

.mapContainer {
    height: 80vh;
    display: block;
}

@media screen and (max-width: 480px) {
    .mapContainer {
        height: 40vh;
        display: block;
    }
}

.mapContainerClosed {
    display: none;
}

.descriptionBox {
    border: 1px solid #cccccc;
    height: 100px;
    overflow-y: scroll;
}

.eventDateTime {
    font-size: 16px;
    font-weight: bold;
}

.pwpHeader {
    font-family: 'Cairo';
    background-color: #1069ae;
    color: #fff;
    font-weight: bold;
}

.pwpHeaderBrand.navbar-brand {
    font-size: 25px;
}


.btn.btn-primary {
    background-color: #1069ae;
    color: #fff;
    font-weight: bold;
}

.searchBox {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
}

.searchBox label {
    float: left;
    margin-left: 0px;
    margin-top: 6px;

    padding-top: 4px;
}
.searchBox input {
    width: 120px;
    float: left;
    margin: 5px;
}

.searchBox button {
    margin: 2px;
    padding: 3px;
    margin-top: 8px;

}

.crosshairDiv {
    float: left;
    height: 100%;
    margin-top: 10px;
    margin-left: 10px;
}

.searchButton {
    float: left;
}


.headerLink {
    color: #fff;
    float: right;
    margin-left: 20px;
}

.dropdownMenuLink {
    color: #1069ae;
}


.listViewToggle {
    float: left;
    margin-top: 5px;
    margin-left: 30px;
}

.listViewToggle .nav-link {
    padding: 5px;
}

.mobileMenu {
    color: #FFF;
    float: left;
}

.modal-content-sm {
    width: 92% !important;
}

.modal-content-lg {

}

.modal-content.loadingModal {
    width: 30%;
    margin-left: 35%;
}

.modal-content.welcomeMesageModal {
    width: 100%;
}

.closeButton {
    float: right;
    margin-left: 3px;
}

.hideCloseButton {
    display: none;
}

.card-noborder {
    @extend .card;
    border: none;
}

.collapseButton {
    text-align: center;
}

.modalClose {
    float: right;
}

.modalClose .nav-link {
    padding: 0px;
    margin: 0px;
}

.welcomeMessage {
    float: left;
    width: 100%;
    margin-top: 10px;
}

.welcomeMessageContainer {
    width: 100%;

}

.welcomeMessageCloser {
    text-align: center;
}

.adminButton {
    margin: 2px;
}

.confirmMessage {
    text-align: center;
    width: 100%;
}

.confirmButtons {
    text-align: center;
    width: 100%;
    margin-top: 30px;
}

.confirmButtons .btn {
    margin-left: 10px;
}

.addButton {
    background-color: #1069ae;
    color: #fff;
    border-radius: 50%;
    padding: 5px;
    margin-left: 10px;
}

.findEventsPanel {
    padding-left: 10px;
}

.about {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.about h2 {
    padding-bottom: 20px;
}

.buttonRow {
    height: 45px;
}
.nextButton {
    position: absolute;
    right: 5%;
    top: 1%;
}

.previousButton {
    position: absolute;
    left: 5%;
    top: 1%;
}

.notFound h1 {
    width: 100%;
    text-align:center;
    margin-top:20%;
}

.notFound h2 {
    width: 100%;
    text-align:center;
}